import React from "react"
import styled from "styled-components"
import { useSpring, animated } from "react-spring"
import "intersection-observer" // optional polyfill
import { useInView } from "react-intersection-observer"
import { LeftCar, RightCar } from "./hero-image"

import device from "../device"

const LeftWrapper = styled(animated.div)`
  position: absolute;
  bottom: -10%;
  width: 45vmax;
  max-width: 900px;
  ${device.medium`width: 42vmax;bottom: -7%;`}
  ${device.small`width: 280px; left: -20%; bottom: -5%;`}
`
const RightWrapper = styled(animated.div)`
  position: absolute;
  bottom: -8%;
  right: 0;
  width: 45vmax;
  max-width: 900px;
  ${device.medium`width: 42vmax; bottom: -5%;`}
  ${device.small`width: 320px; bottom: -7%; right: -20%;`}
`

const Cars = () => {
  const [ref, inView] = useInView({ triggerOnce: true })

  const springRight = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? "translate3d(0,0,0)" : "translate3d(100px,0,0)",
    from: { opacity: 0, transform: "translate3d(100px,0,0)" },
    delay: 1000,
  })

  const springLeft = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? "translate3d(0,0,0)" : "translate3d(-100px,0,0)",
    from: { opacity: 0, transform: "translate3d(-100px,0,0)" },
    delay: 1500,
  })

  return (
    <div ref={ref}>
      <LeftWrapper style={springLeft}>
        <LeftCar />
      </LeftWrapper>
      <RightWrapper style={springRight}>
        <RightCar />
      </RightWrapper>
    </div>
  )
}

export default Cars
