import React, { useState } from "react"
import styled, { keyframes } from "styled-components"
import device from "../device"
import { Bg, Road } from "./hero-image"
import Cars from "./cars"
import SecondaryHeader from "../header/header-secondary"
import FsLightbox from 'fslightbox-react'
import { useStaticQuery, graphql } from "gatsby"

const fadeIn = keyframes`
  from {
    background-color: var(--darkgrey);
  }
  to {
    background-color: none;
  }
`

const Wrapper = styled.section`
  animation: ${fadeIn} 1s 1s ease-out;
  animation-fill-mode: backwards;
  color: var(--white);
  position: relative;
  height: 85vh;
  max-height: 900px;
  ${device.medium`height: 80vh;`}
  ${device.small`height: 70vh;`}
  ${device.large`height: 80vh; max-height: 1080px;`}
`
const Banner = styled.div`
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
`
const Text = styled.div`
  max-width: var(--limit);
  margin: 0 auto;
  padding: 2rem 0;
  ${device.small`padding: 5rem 0;`}
  ${device.large`padding: 10rem 0;`}
  .heading-wrapper {
    width: 60%;
    margin: 0 auto;
    ${device.medium`width: 75%;`}
    ${device.small`width: var(--spread);`}
    .heading {
      text-align: center;
      display: inline-block;
      font-size: 3rem;
      font-style: italic;
      ${device.small`display: block; line-height: 1; font-size: 2.5rem;`}
      ${device.large`font-size: 4.5rem;`}
      &.sub {
        line-height: 1.1;
        .first {
          line-height: 0.5;
          margin-left: 6rem;
          ${device.large`margin-left: 10rem;`}
        }
        .last {
          letter-spacing: 20px;
          ${device.small`letter-spacing: 10px; line-height: 1.5;`}
        }
      }

      &.fleet {
        text-align: left;
        line-height: 1;
        ${device.small`text-align: center;`}
        .last {
          margin-left: 2rem;
        }
      }
    }
  }
`
const RoadWrapper = styled.div`
  position: relative;
  z-index: -2;
  margin-top: -6%;
  ${device.small`margin: 0 0 2rem 0;`}
`

const Hero = ({ bannerData, cars, children }) => {
  const data = useStaticQuery(graphql`
{
  file(relativePath: { eq: "hero/play.png" }) {
    childImageSharp {
      fluid(maxWidth: 195, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  }
}
`)
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1
    });
    
    function openLightboxOnSlide(number) {
    setLightboxController({
    toggler: !lightboxController.toggler,
    slide: number
    });
    }
  return (
    <div style={{ overflow: "hidden" }}>

      <Wrapper>
        <Banner>
          {bannerData.desktop &&
          bannerData.mobile &&
          bannerData.desktop.localFile &&
          bannerData.mobile.localFile ? (
            <Bg data={bannerData} />
          ) : (
            <div
              style={{
                background: "var(--black)",
                width: "100%",
                height: "100%",
              }}
            ></div>
          )}
        </Banner>
        <SecondaryHeader />
        <Text>
          <div className="heading-wrapper">{children}</div>
        </Text>
        {cars && <Cars />}
      </Wrapper>

      <RoadWrapper>
        <Road />
      </RoadWrapper>
      
    </div>
  )
}

export default Hero
