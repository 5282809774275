import { useStaticQuery, graphql } from "gatsby"

export const useTravelDestinationsQuery = () => {
  const { genPagesOrNot, categories } = useStaticQuery(
    graphql`
      query TravelDestinations {
        genPagesOrNot: wpgraphql {
          page(id: "54", idType: DATABASE_ID) {
            seoComponent {
              seoComponentFancyHeading
              seoComponentLessFancyHeading
              seoComponentServices {
                seoComponentServiceTitle
                seoComponentTypeOfContent
                seoComponentPageCategory {
                  name
                }
                seoComponentContent
              }
            }
          }
        }
        categories: wpgraphql {
          categories(first: 100) {
            edges {
              node {
                id
                name
                slug
                pages(
                  where: { orderby: { field: TITLE, order: ASC } }
                  first: 100
                ) {
                  nodes {
                    title
                    uri
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return { genPagesOrNot, categories }
}
